import { ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale-provider/zh_TW'
import 'braft-editor/dist/index.css'
import 'braft-editor/dist/output.css'
import React from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { AuthProvider } from './components/auth/AuthContext'
import { ApiProvider } from './components/common/ApiContext'
import { AppThemeProvider } from './components/common/AppThemeContext'
import { AppProvider } from './containers/common/AppContext'
import ErrorBoundary from './containers/common/ErrorBoundary'
import { CartProvider } from './contexts/CartContext'
import { LanguageProvider } from './contexts/LanguageContext'
import { NotificationProvider } from './contexts/NotificationContext'
import { PodcastPlayerProvider } from './contexts/PodcastPlayerContext'
import Routes from './Routes'

const Application: React.FC = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AuthProvider>
          <ApiProvider>
            <AppProvider>
              <LanguageProvider>
                <CartProvider>
                  <NotificationProvider>
                    <PodcastPlayerProvider>
                      <AppThemeProvider>
                        <ErrorBoundary>
                          <ConfigProvider locale={zhTW}>
                            <Routes />
                          </ConfigProvider>
                        </ErrorBoundary>
                      </AppThemeProvider>
                    </PodcastPlayerProvider>
                  </NotificationProvider>
                </CartProvider>
              </LanguageProvider>
            </AppProvider>
          </ApiProvider>
        </AuthProvider>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default Application
